import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  Button,
  classNames,
  Tooltip,
  TooltipContent,
  TooltipPrimitive,
  TooltipProvider,
  TooltipTrigger,
} from '@antcorefinance/ui';
import { CheckCircle2, Check, FileQuestionIcon } from 'lucide-react';
import { signIn } from 'next-auth/react';
import {
  ProvideLiquidtyTask,
  PoolCarousel,
  AllocateButton,
  PuzzleButton,
  TelegramComponent,
  // LockAptosTask,
} from './poolCarousel';
import { AntcIcon } from '@antcorefinance/ui/icons/AntcIcon';

// import { Input } from 'antd';
import Image from 'next/image';
import { textFieldVariants } from '@antcorefinance/ui';
// import { Pencil } from 'lucide-react';
// import FaucetButton from './faucet';
// import TaskCompletionList from './ctfCompletion';

interface AntModalProps {
  walletAddress?: any;
  isOpen: boolean;
  onClose: () => void;
  taskId: number;
  taskTitle: string;
  subTasks: { id: number; title: string; points: number }[];
  onCompleteTask: (subTaskId: number, subTaskPoints: number, password?: string) => void;
  completedSubTasks: number[];
}

const TooltipIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2.25C10.0716 2.25 8.18657 2.82183 6.58319 3.89317C4.97982 4.96451 3.73013 6.48726 2.99218 8.26884C2.25422 10.0504 2.06114 12.0108 2.43735 13.9021C2.81355 15.7934 3.74215 17.5307 5.10571 18.8943C6.46928 20.2579 8.20656 21.1865 10.0979 21.5627C11.9892 21.9389 13.9496 21.7458 15.7312 21.0078C17.5127 20.2699 19.0355 19.0202 20.1068 17.4168C21.1782 15.8134 21.75 13.9284 21.75 12C21.7473 9.41498 20.7192 6.93661 18.8913 5.10872C17.0634 3.28084 14.585 2.25273 12 2.25ZM12 20.25C10.3683 20.25 8.77326 19.7661 7.41655 18.8596C6.05984 17.9531 5.00242 16.6646 4.378 15.1571C3.75358 13.6496 3.5902 11.9908 3.90853 10.3905C4.22685 8.79016 5.01259 7.32015 6.16637 6.16637C7.32016 5.01259 8.79017 4.22685 10.3905 3.90852C11.9909 3.59019 13.6497 3.75357 15.1571 4.37799C16.6646 5.00242 17.9531 6.05984 18.8596 7.41655C19.7661 8.77325 20.25 10.3683 20.25 12C20.2475 14.1873 19.3775 16.2843 17.8309 17.8309C16.2843 19.3775 14.1873 20.2475 12 20.25ZM13.5 16.5C13.5 16.6989 13.421 16.8897 13.2803 17.0303C13.1397 17.171 12.9489 17.25 12.75 17.25C12.3522 17.25 11.9706 17.092 11.6893 16.8107C11.408 16.5294 11.25 16.1478 11.25 15.75V12C11.0511 12 10.8603 11.921 10.7197 11.7803C10.579 11.6397 10.5 11.4489 10.5 11.25C10.5 11.0511 10.579 10.8603 10.7197 10.7197C10.8603 10.579 11.0511 10.5 11.25 10.5C11.6478 10.5 12.0294 10.658 12.3107 10.9393C12.592 11.2206 12.75 11.6022 12.75 12V15.75C12.9489 15.75 13.1397 15.829 13.2803 15.9697C13.421 16.1103 13.5 16.3011 13.5 16.5ZM10.5 7.875C10.5 7.6525 10.566 7.43499 10.6896 7.24998C10.8132 7.06498 10.9889 6.92078 11.1945 6.83564C11.4001 6.75049 11.6263 6.72821 11.8445 6.77162C12.0627 6.81502 12.2632 6.92217 12.4205 7.0795C12.5778 7.23684 12.685 7.43729 12.7284 7.65552C12.7718 7.87375 12.7495 8.09995 12.6644 8.30552C12.5792 8.51109 12.435 8.68679 12.25 8.8104C12.065 8.93402 11.8475 9 11.625 9C11.3266 9 11.0405 8.88147 10.8295 8.6705C10.6185 8.45952 10.5 8.17337 10.5 7.875Z"
      fill="#0F0F0F"
    ></path>
  </svg>
);

const AntModal: React.FC<AntModalProps> = ({
  walletAddress,
  isOpen,
  onClose,
  taskId,
  taskTitle,
  subTasks,
  onCompleteTask,
  completedSubTasks,
}) => {
  const [processingTaskId, setProcessingTaskId] = useState<number | null>(null);
  const [modalHeight, setModalHeight] = useState<string>('100vh');
  // Inside your component
  const [selectedAmount, setSelectedAmount] = useState(0); // Default value
  const [manualInput, setManualInput] = useState(''); // To handle manual input
  const [answerInput, setAnswerInput] = useState(''); // To handle manual input
  const [showInput, setShowInput] = useState(false); // To toggle input visibility

  // Function to handle amount selection
  const handleSelectAmount = (amount: any) => {
    setSelectedAmount(amount);
    setManualInput(''); // Clear manual input when a button is clicked
  };

  // Function to handle manual input
  const handleManualInput = (e: any) => {
    const value = e.target.value;
    // Check to ensure the value is a number and within the allowed range
    if (/^\d*$/.test(value) && Number(value) >= 0 && Number(value) <= 20) {
      setSelectedAmount(Number(value));
      setManualInput(value);
    }
  };

  // Function to handle the visibility of the input
  const toggleInputVisibility = () => {
    setShowInput(!showInput);
  };

  // Allocation function
  const handleAllocate = () => {
    // Add allocation logic using the selectedAmount

    console.log(`Allocating ${selectedAmount} APT for ANTC`);
  };

  // Lock body scroll when the modal is open
  useEffect(() => {
    const updateModalHeight = () => {
      const height = `${window.innerHeight}px`;
      setModalHeight(height);
    };

    if (isOpen) {
      document.body.style.overflow = 'hidden';
      updateModalHeight();
      window.addEventListener('resize', updateModalHeight);
    } else {
      document.body.style.overflow = 'visible';
    }

    return () => {
      window.removeEventListener('resize', updateModalHeight);
      document.body.classList.remove('modal-open');
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const handleSignIn = async () => {
    try {
      const result = await signIn('twitter');

      if (result?.error) {
        console.log('Sign in failed:', result.error);
        return;
      }

      // Automatically bind Twitter after successful sign-in
    } catch (error: any) {
      console.log('Error signing in:', error.message);
    }
  };

  const handleCompleteTask = async (
    subTaskId: number,
    subTaskPoints: number,
    password?: string,
  ) => {
    setProcessingTaskId(subTaskId);
    await onCompleteTask(subTaskId, subTaskPoints, password);
    setProcessingTaskId(null);
  };

  return createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-80">
      <div
        className="card-border card-shadow w-full max-w-[480px] overflow-y-auto rounded-lg bg-black p-6"
        style={{ maxHeight: modalHeight }}
      >
        <h2 className="mb-2 text-center text-2xl font-bold text-gray-50">Complete Task</h2>
        <div className="m-auto mb-4">
          {subTasks.map((subTask, index) => {
            // Check if the previous task is completed
            const isPreviousTaskCompleted =
              index === 0 || completedSubTasks.includes(subTasks[index - 1].id);

            const isTaskCompleted = completedSubTasks.includes(subTask.id);
            const isLastTaskCompleted = completedSubTasks.includes(6);
            return (
              <div
                key={subTask.id}
                className={`relative mb-2 mt-8 ${!isPreviousTaskCompleted ? '' : ''}`}
              >
                {/* Overlay for locked tasks */}
                {!isPreviousTaskCompleted && subTask.id !== 8 && (
                  <div className="absolute inset-0 z-10 flex items-center justify-center bg-black bg-opacity-75">
                    <div className="font-bold text-white">Complete previous tasks to unlock</div>
                  </div>
                )}

                {isTaskCompleted && (
                  <div className="absolute inset-0 z-10 flex items-center justify-center bg-black bg-opacity-75">
                    <div className="flex flex-row gap-2 font-bold text-[#2ab246]">
                      <CheckCircle2 color="#2ab246"> </CheckCircle2>Task Completed
                    </div>
                  </div>
                )}

                {/* Overlay for completed tasks */}
                {/* {isTaskCompleted && (
                  <div className="absolute inset-0 bg-[#000000ba] z-10 flex items-center bg-opacity-80 justify-center">
                    <div className="text-white font-bold">Task Completed</div>
                  </div>
                )} */}

                {subTask.id === 5 ? (
                  <AntcIcon width="80" className="m-auto mb-6 w-[120]"></AntcIcon>
                ) : subTask.id === 8 ? (
                  <p className="mb-4 text-center text-lg font-semibold text-yellow-50"></p>
                ) : (
                  <p className="mb-4 text-center text-lg font-semibold text-gray-50">
                    {subTask.title}
                  </p>
                )}

                {/* Task-specific content based on task ID */}
                {/* Existing task content rendering remains the same */}

                {subTask.id === 1 && (
                  <div className="flex h-full w-full flex-col gap-4">
                    <div className="flex items-center gap-1">
                      <Button
                        onClick={handleSignIn}
                        variant="outline"
                        className="m-auto w-full !rounded-md bg-[#171717]"
                      >
                        <svg
                          width="18px"
                          height="18px"
                          viewBox="0 0 356 322"
                          fill="#fff"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M280.335 0H334.917L215.672 136.29L355.954 321.75H246.114L160.083 209.269L61.644 321.75H7.029L134.574 175.972L0 0H112.629L190.394 102.811L280.335 0ZM261.179 289.08H291.423L96.195 30.954H63.7395L261.179 289.08Z"
                            fill="var(--btn-txt-color)"
                          />
                        </svg>
                        <div className="text-sm font-medium">Login</div>
                      </Button>
                    </div>
                    <div className="flex items-center gap-1">
                      <Button
                        variant="outline"
                        className="m-auto w-full !rounded-md bg-[#171717] !py-6"
                      >
                        <div className="flex items-center">
                          <Image width={40} height={40} src="/logo.png" alt="Antcore Finance" />
                        </div>
                        <a
                          className="twitter-follow-button"
                          target="_blank"
                          referrerPolicy="no-referrer"
                          href="https://x.com/intent/follow?screen_name=antcorefinance"
                          data-size="large"
                        >
                          Follow @antcorefinance
                        </a>
                      </Button>
                    </div>
                  </div>
                )}

                {subTask.id === 2 && (
                  <iframe
                    id="twitter-widget"
                    title="Twitter Tweet"
                    className="h-[290px] sm:h-[325px]"
                    width="100%"
                    style={{ colorScheme: 'normal' }}
                    src="https://platform.twitter.com/embed/Tweet.html?dnt=false&amp;frame=true&amp;hideCard=true&amp;hideThread=true&amp;id=1859285870353973483&amp;lang=en&amp;theme=dark&amp;height=340"
                    data-gtm-yt-inspected-6="true"
                  ></iframe>
                )}

                {subTask.id === 3 && (
                  <div className="flex h-full w-full flex-col gap-4">
                    <PoolCarousel />
                    {/* <FaucetButton /> */}

                    <p> (i) Make sure you have 1 Testnet APT + gas fee.</p>
                    <p> (ii) Your wallet is connected to the Aptos Testnet.</p>
                    {/* <p>(iii) Join Colony TG channel after successful mint.</p> */}
                  </div>
                )}

                {subTask.id === 4 && (
                  <div className="flex h-full w-full flex-col gap-4">
                    <ProvideLiquidtyTask />
                    <p>
                      (i)
                      <a
                        href="https://antcore.finance/swap?outputCurrency=0xc4afaf44985dfaa57d0a64b4151c3998238678c25b0bf24dae345a044cb7d058%3A%3Aantcore%3A%3AAntCoin"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml-2 mr-2 text-blue-500 underline hover:text-blue-700"
                      >
                        Swap APT for ANTC on antcore.finance
                      </a>
                    </p>
                    <p>
                      (ii)
                      <a
                        href="https://antcore.finance/add/0x1::aptos_coin::AptosCoin/0xc4afaf44985dfaa57d0a64b4151c3998238678c25b0bf24dae345a044cb7d058::antcore::AntCoin"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml-2 mr-2 text-blue-500 underline hover:text-blue-700"
                      >
                        Provide ANTC-APT Liquidity
                      </a>
                    </p>
                    <p>
                      (iii)
                      <a
                        href="https://antcore.finance/farms"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml-2 mr-2 text-blue-500 underline hover:text-blue-700"
                      >
                        Stake ANTC-APT LP
                      </a>
                    </p>
                  </div>
                )}

                {subTask.id === 5 && (
                  <div className="flex h-full w-full flex-col gap-4">
                    <p className="m-auto text-center">
                      Allocate APT and collaborate with Antcore Workers.
                    </p>
                    {/* <div className="flex items-center space-x-2 m-auto">
                      <p>Lock in your APT for ANTC for the public sale.</p>
                      <TooltipPrimitive.Provider delayDuration={0}>
                        <TooltipPrimitive.Root>
                          <TooltipPrimitive.Trigger asChild>
                            <button aria-label="More info">
                              <FileQuestionIcon className="w-5 h-5 pointer-events-none	 text-gray-500 z-50 hover:text-gray-700" />
                            </button>
                          </TooltipPrimitive.Trigger>
                          <TooltipPrimitive.Portal>
                            <TooltipPrimitive.Content
                              className="bg-gray-800  text-white p-2 rounded shadow-lg z-50 text-sm"
                              sideOffset={5}
                              onClick={(e) => e.stopPropagation()} // Optional: Prevents click from closing the tooltip if that's desired.
                            >
                              This locks your APT for ANTC during the public
                              sale.
                              <TooltipPrimitive.Arrow className="fill-gray-800" />
                            </TooltipPrimitive.Content>
                          </TooltipPrimitive.Portal>
                        </TooltipPrimitive.Root>
                      </TooltipPrimitive.Provider>
                    </div> */}

                    {/* <div className="flex gap-1">
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <div className="whitespace-nowrap  text-green  px-2 rounded-full">
                              <FileQuestionIcon />
                            </div>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Farm rewards available</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>

                    <span className="text-base m-auto fnt-body-one text-modern-dark font-normal leading-snug relative flex items-center gap-x-2 !text-document-secondary">
                      <span>
                        {" "}
                        Lock in your APT for ANTC for the public sale.
                      </span>
                      <div className="group">
                        <TooltipIcon />
                        <div className="absolute top-6 hidden min-w-[300px] rounded-xl bg-modern-dark p-4 text-sm text-alt-white group-hover:block ">
                          Complete the tasks in the Achievements section to
                          increase your multiplier.
                        </div>
                      </div>
                    </span> */}

                    {/* <LockAptosTask /> */}
                    <div className="relative mx-auto mt-4 w-full max-w-md">
                      <input
                        autoFocus={isTaskCompleted ? false : true}
                        onChange={handleManualInput}
                        className={`h-12 w-full rounded-lg border px-4 py-2 text-lg text-white ${
                          selectedAmount || manualInput
                            ? 'border-gray-500 placeholder-[#fff]'
                            : 'placeholder-text-white border-gray-700'
                        } transition-all duration-200 focus:border-gray-500 focus:outline-none`}
                        autoCorrect="off"
                        autoCapitalize="none"
                        value={
                          [1, 3, 5, 10, 15, 25, 50, 75, 99].includes(selectedAmount)
                            ? selectedAmount
                            : manualInput
                        }
                        placeholder={selectedAmount?.toString() || 'Enter amount'}
                        spellCheck="false"
                        autoComplete="off"
                      />

                      <div className="absolute right-4 top-1/2 flex -translate-y-1/2 transform items-center gap-2">
                        <Image
                          width={20}
                          height={20}
                          src="https://tokens-antcore.vercel.app/images/aptos/0x1::aptos_coin::AptosCoin.png"
                          alt="APT Token"
                        />
                        <span className="text-white">APT</span>
                      </div>
                    </div>

                    <div className="flex w-full items-center gap-4">
                      <input
                        type="range"
                        min="1"
                        max="20"
                        step="1"
                        className="h-2 w-full cursor-pointer appearance-none rounded-lg"
                        style={{
                          background: '#1c1c1c',
                          outline: 'none',
                          WebkitAppearance: 'none',
                          borderRadius: '9999px',
                          backgroundImage: `linear-gradient(to right, #4a4a4a, #fff ${
                            ((selectedAmount || 1) / 20) * 100
                          }%, #2a2a2a 100%)`,
                        }}
                        value={selectedAmount || 1}
                        onChange={(e) => handleSelectAmount(Number(e.target.value))}
                      />
                    </div>

                    <style jsx>{`
                      input[type='range']::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        background: none;

                        background-size: cover;
                        cursor: pointer;
                      }

                      input[type='range']::-moz-range-thumb {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        background: none;
                        background-size: cover;
                        cursor: pointer;
                      }
                    `}</style>
                    {/* backgroundImage: `linear-gradient(to right, #f0c27b, #f0ab44, #f4d03f, #ffcc00 ${ */}

                    {/* <div className="flex w-full items-center gap-2">
                      {(showInput || Number(manualInput) >= 1) && (
                        <Input
                          variant="outlined"
                          type="number"
                          min="1"
                          max="99"
                          value={manualInput}
                          placeholder={"Enter an amount"}
                          onChange={handleManualInput}
                          className="w-20 m-auto placeholder:text-gray-400 !rounded-full px-4 !bg-[#1c1c1c] !text-white border-none "
                          onBlur={() => setShowInput(false)} // Hide input on blur (optional)
                        />
                      )}
                    </div> */}

                    <AllocateButton
                      amount={Number(manualInput) > 0 ? Number(manualInput) : selectedAmount}
                    />
                    {/* <Button
                      variant="outline"
                      className="!rounded-md !py-4 w-full m-auto bg-[#171717] text-base font-semibold"
                      onClick={() => AllocateButton(selectedAmount)}
                    >
                      <div className="text-sm ">
                        Allocate {selectedAmount} APT
                      </div>
                    </Button> */}

                    <p className="whitespace-nowrap">
                      {' '}
                      (i) Make sure your wallet is connected to the <strong> APT Mainnet.</strong>
                    </p>
                    <p>(ii) Allocate a minimum of 1 and a maximum of 20 APT.</p>
                    <p>
                      Details:{' '}
                      <a href="https://docs.antcore.finance/ido" target="_blank">
                        {' '}
                        https://docs.antcore.finance/ido{' '}
                      </a>
                    </p>

                    {/* <p>(iii) Receive double ANTC allocation at the TGE</p> */}

                    {/* <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div className="whitespace-nowrap  text-green text-[10px] px-2 rounded-full">
                            🧑‍🌾
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>Farm rewards available</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider> */}
                  </div>
                )}

                {subTask.id === 6 && (
                  <div className="flex h-full w-full flex-col gap-4">
                    <TelegramComponent />
                  </div>
                )}

                {subTask.id === 7 && (
                  <div className="flex h-full w-full flex-col gap-4">
                    <p className="m-auto text-center">Enter the decoded message:</p>
                    <input
                      type="text"
                      className="m-auto mt-4 w-full max-w-sm rounded-lg border bg-gray-800 px-4 py-2 text-lg text-white"
                      placeholder="Enter your answer"
                      value={manualInput}
                      onChange={(e) => setManualInput(e.target.value)}
                    />

                    <Button
                      variant="antc"
                      disabled={processingTaskId === subTask.id}
                      className="m-auto mt-4 w-full max-w-sm rounded-md !bg-[#077d1e] py-2 text-white"
                      onClick={() => {
                        handleCompleteTask(subTask.id, subTask.points, manualInput);
                      }}
                    >
                      {processingTaskId === subTask.id ? (
                        'Processing...'
                      ) : completedSubTasks.includes(subTask.id) ? (
                        'Completed'
                      ) : (
                        <>UNLOCK TASK</>
                      )}{' '}
                    </Button>
                  </div>
                )}

                {subTask.id === 8 && (
                  <div className="flex h-full w-full flex-col gap-4">
                    <p
                      onClick={() => {
                        const fileUrl = '/finalpuzzle.txt';
                        const a = document.createElement('a');
                        a.href = fileUrl;
                        a.download = 'FinalPuzzle.txt';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                      }}
                      className="cursor-pointer text-center text-blue-500 underline hover:text-blue-700"
                    >
                      FinalPuzzle.txt
                    </p>

                    {/* <Button
                      variant="antc"
                      className="m-auto mt-4 w-full max-w-sm rounded-md bg-[#171717] py-2 text-white"
                      onClick={() => {
                        const fileUrl = '/CompletionCertificate.txt'; // File in the public folder
                        const a = document.createElement('a');
                        a.href = fileUrl;
                        a.download = 'CompletionCertificate.txt'; // Rename the file if necessary
                        a.click();
                      }}
                    >
                      Download
                    </Button> */}
                  </div>
                )}
                {subTask.id === 8 && (
                  <div className="flex h-full w-full flex-col gap-4">
                    {/* <p className="text-center text-white">
                      Enter the answer to verify and unlock the reward:
                    </p> */}
                    <div className="mt-4 flex flex-col gap-2 px-5 text-sm">
                      <p> 🌀 Everything is not as it seems. Look closer.</p>
                      <p> 🌀 The treasure is hidden, and only the sharpest eyes will find it.</p>
                      <p>
                        {' '}
                        🌀 What you’re seeking isn’t in the parts—it’s in how they come together.
                      </p>
                    </div>

                    <input
                      type="text"
                      className="m-auto mt-2 w-full max-w-sm rounded-lg border bg-gray-800 px-4 py-2 text-lg text-white"
                      placeholder="Enter your answer"
                      value={answerInput}
                      onChange={(e) => setAnswerInput(e.target.value)}
                    />

                    <PuzzleButton answer={answerInput} />
                    {/* <Button
                      variant="antc"
                      disabled={processingTaskId === subTask.id}
                      className="m-auto mt-4 w-full max-w-sm rounded-md bg-[#171717] py-2 text-white"
                      onClick={() => handleCompleteTask(subTask.id, subTask.points, answerInput)}
                    >
                      {processingTaskId === subTask.id ? (
                        'Processing...'
                      ) : completedSubTasks.includes(subTask.id) ? (
                        'Completed'
                      ) : (
                        <>Capture The Flag</>
                      )}{' '}
                    </Button> */}
                  </div>
                )}

                {subTask.id !== 7 && subTask.id !== 8 && (
                  <Button
                    variant="antc"
                    onClick={() => handleCompleteTask(subTask.id, subTask.points)}
                    className="m-auto mt-6 !flex w-full !rounded-xl px-4 py-2 !text-base"
                    disabled={
                      processingTaskId === subTask.id ||
                      completedSubTasks.includes(subTask.id) ||
                      !isPreviousTaskCompleted
                    }
                  >
                    {processingTaskId === subTask.id ? (
                      'Processing...'
                    ) : completedSubTasks.includes(subTask.id) ? (
                      'Completed'
                    ) : (
                      <>
                        <Check />
                        Mark as completed
                      </>
                    )}
                  </Button>
                )}
              </div>
            );
          })}
        </div>
        <div className="mt-10 flex justify-center">
          <Button
            variant="outlineclose"
            onClick={onClose}
            className="rounded-md px-4 py-2 text-white"
          >
            Close
          </Button>
        </div>

        {/* {taskId === 4 && <TaskCompletionList taskId={8} />} */}
      </div>
    </div>,
    document.body,
  );
};

export default AntModal;
