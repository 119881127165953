import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';

interface Faction {
  name: string;
  points: number;
  totalUsers: number;
}

const FactionStats: React.FC = () => {
  const [factionData, setFactionData] = useState<Faction[]>(() => {
    const cachedData = localStorage.getItem('factionData');
    return cachedData
      ? JSON.parse(cachedData)
      : [
          { name: 'Red Swarm', points: 0.0, totalUsers: 0 },
          { name: 'Blue Nest', points: 0.0, totalUsers: 0 },
          { name: 'Purple Hive', points: 0.0, totalUsers: 0 },
        ];
  });

  const [previousPoints, setPreviousPoints] = useState<number[]>(() => {
    const cachedData = localStorage.getItem('factionData');
    return cachedData
      ? JSON.parse(cachedData).map((faction: Faction) => faction.points)
      : [0, 0, 0];
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const factionOrder = ['Red Swarm', 'Blue Nest', 'Purple Hive'];

  useEffect(() => {
    let isFetching = false;

    const fetchFactionData = async () => {
      if (isFetching) return; // Avoid multiple fetches
      isFetching = true;

      try {
        const response = await fetch('/api/faction-points');
        const data = await response.json();

        if (data.success) {
          const sortedData = data.factions.sort(
            (a: Faction, b: Faction) => factionOrder.indexOf(a.name) - factionOrder.indexOf(b.name),
          );

          // Update previous points
          setPreviousPoints(factionData?.map((faction) => faction.points) || []);

          // Update faction data
          setFactionData(sortedData);
          localStorage.setItem('factionData', JSON.stringify(sortedData));

          // Clear any previous errors
          setError(null);
        } else {
          setError(data.error);
        }
      } catch (err) {
        console.error('Error fetching faction data:', err);
        setError('Failed to load faction data.');
      } finally {
        isFetching = false;
        setLoading(false);
      }
    };

    // Fetch data immediately
    fetchFactionData();

    // Set up the interval
    const intervalId = setInterval(fetchFactionData, 10000);

    // Cleanup on unmount
    return () => clearInterval(intervalId);
  }, []); // Removed `factionData` from dependency array

  return (
    <div className="xs:my-8 md:my-12">
      <section className="card-border m-auto rounded-lg bg-cardBlack p-4 text-center">
        <div className="flex flex-col justify-between gap-6 sm:flex-row">
          {factionData.map((faction, index) => (
            <div
              key={index}
              className={`border-alt-grey xs:max-md:border-b xs:max-md:pb-6 md:basis-1/3 ${
                index !== factionData.length - 1 ? 'md:border-r' : ''
              }`}
            >
              <h6
                className={`fnt-body-one text-modern-dark !text-document-secondary xs:max-md:mb-2 text-sm font-medium leading-snug ${
                  index === 0 ? 'text-red' : index === 1 ? 'text-blue' : 'text-purple-600'
                }`}
              >
                {faction.name}
              </h6>
              <div className="xs:text-[21px] relative font-bold md:text-2xl">
                <CountUp
                  start={previousPoints[index]}
                  end={faction.points}
                  decimals={0}
                  duration={1}
                  separator=","
                />
              </div>
              <p className="fnt-body-one text-modern-dark !text-document-secondary xs:max-md:mb-2 text-sm font-medium leading-snug">
                Total Players: {faction.totalUsers}
              </p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default FactionStats;
