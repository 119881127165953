import React, { useCallback, useEffect, useState } from 'react';
import { Provider } from 'aptos';
import { networkNameToNetwork } from 'config/chains';
import { useNetwork } from 'lib/common/use-network';
import {
  getNftPayload,
  mintNftPayload,
  LockAPTPayload,
  puzzlePayload,
} from 'lib/portal/get-nft-payload';
import { useWallet } from '@aptos-labs/wallet-adapter-react';
import { createToast } from 'ui/common/toast';
import { motion, AnimatePresence } from 'framer-motion';
import ParallaxCard from './parralax_card'; // Adjust the path as necessary
import ColonySvgLogo from './colonylogo';
import LogoAnimated from './LogoAnimated';
import { AntcIcon } from '@antcorefinance/ui/icons/AntcIcon';
import { Button } from '@antcorefinance/ui';
import requiredNetworkAlert from '@/lib/common/required-network-alert';
import { Alert, message, Space, Spin, Typography } from 'antd';
import { getOrCreateInviteLink } from './events';
import { Loader as LoadingOutlined } from 'lucide-react';
import { link } from 'fs';
import Image from 'next/image';
import { sha3_512 } from 'js-sha3';

function sha3_512Encode(input: string): string {
  return sha3_512(input); // Returns the correct SHA3-512 hash as a hexadecimal string
}

export const PoolCarousel: React.FC<any> = ({ items, type }) => {
  const { network } = useNetwork();
  const { account, network: myNetwork, disconnect, signAndSubmitTransaction } = useWallet();

  //   useEffect(() => {
  //     requiredNetworkAlert(network, disconnect);
  //   }, [network, disconnect]);

  const mintNft = useCallback(async () => {
    if (myNetwork?.name?.toLowerCase() === 'mainnet') {
      //   disconnect();
      alert('Switch your network to aptos testnet and try again');
      return;
    }

    if (!account?.address) {
      alert('Please connect to a wallet');
      return;
    }

    const provider = new Provider(networkNameToNetwork(network));

    const payload = mintNftPayload();

    try {
      const response = await signAndSubmitTransaction(payload);
      await provider.waitForTransaction(response?.hash);
      if (!response?.output.success) return;
      const toastId = `completed:${response?.hash}`;
      const summary = 'Mint successful!';
      // createToast({
      //   summery: summary,
      //   toastId: toastId,
      // });
      message.success('Mint Successful');
    } catch (_e) {
      const toastId = `failed:${Math.random()}`;
      message.error('Rejected Request');

      // createToast({ summery: "Rejected request", toastId: toastId });
    }
  }, [network, signAndSubmitTransaction]);

  return (
    <div
      onClick={mintNft} // Add onClick handler here
      className="m-auto w-full flex-col"
    >
      {/* <ParallaxCard> */}
      {/* Place any children here */}

      <div className="flex items-center gap-1">
        <Button variant="outline" className="m-auto w-full !rounded-md bg-[#171717] !py-6">
          <div className="flex items-center">
            <Image width={40} height={40} src="/logo.png" alt="Aptos Dex Antcore" />
          </div>
          <p data-size="large">Mint and Join Antcore Community</p>
        </Button>
      </div>

      {/* <div className="colony__card flex flex-row ">
          <div className="card__img">
            <ColonySvgLogo
              fill="#fff"
              className="m-auto w-full"
              color="#000"
              width="70"
            />
          </div>

          <Button
            variant="outline z-50"
            className="!rounded-md ml-1 !py-6 w-full m-auto bg-[#171717]"
          >
            <span className="twitter-follow-button font-semibold">
              Mint Now
            </span>
          </Button>
        </div> */}
      {/* </ParallaxCard> */}
    </div>
  );
};

export const ProvideLiquidtyTask: React.FC<any> = ({ items, type }) => {
  const { network } = useNetwork();
  const { account, network: myNetwork, disconnect, signAndSubmitTransaction } = useWallet();

  //   useEffect(() => {
  //     requiredNetworkAlert(network, disconnect);
  //   }, [network, disconnect]);

  const mintNft = useCallback(async () => {
    window.open('https://antcore.finance/swap', '_blank');
  }, [network, signAndSubmitTransaction]);

  return (
    <div
      onClick={mintNft} // Add onClick handler here
      className="m-auto w-full flex-col"
    >
      <ParallaxCard>
        {/* Place any children here */}
        <div className="colony__card flex flex-row">
          <div className="card__img w-full">
            <div className="flex items-center gap-1">
              <Image
                width={45}
                height={45}
                src="https://tokens-antcore.vercel.app/images/aptos/0xc4afaf44985dfaa57d0a64b4151c3998238678c25b0bf24dae345a044cb7d058::antcore::AntCoin.png"
                alt="AntCoin"
              />

              <Image
                width={44}
                height={44}
                src="https://tokens-antcore.vercel.app/images/aptos/0x1::aptos_coin::AptosCoin.png"
                alt="AptosCoin"
              />
            </div>
          </div>

          <Button
            variant="outline z-50"
            className="m-auto ml-1 w-full !rounded-md bg-[#171717] !py-6"
          >
            <span className="twitter-follow-button font-semibold">Provide ANTC-APT Liquidity</span>
          </Button>
        </div>
      </ParallaxCard>
    </div>
  );
};

interface AllocateButtonProps {
  amount: number;
}

interface PuzzleButtonProps {
  answer: string;
}

export const AllocateButton: React.FC<AllocateButtonProps> = ({ amount }) => {
  const { network } = useNetwork();
  const { account, network: myNetwork, disconnect, signAndSubmitTransaction } = useWallet();

  //   useEffect(() => {
  //     requiredNetworkAlert(network, disconnect);
  //   }, [network, disconnect]);

  const lockAPT = useCallback(
    async (amountx: number) => {
      if (myNetwork?.name?.toLowerCase() !== 'mainnet') {
        //   disconnect();
        alert('Switch your network to aptos mainnet and try again');
        return;
      }

      if (!account?.address) {
        alert('Please connect to a wallet');
        return;
      }

      const provider = new Provider(networkNameToNetwork(myNetwork.name as any));

      const payload = LockAPTPayload(amountx);

      try {
        const response = await signAndSubmitTransaction(payload);
        await provider.waitForTransaction(response?.hash);
        if (!response?.output.success) return;
        message.success('Allocation Successful!');
      } catch (_e) {
        message.error('Rejected Request');
      }
    },
    [network, signAndSubmitTransaction],
  );

  return (
    <Button
      variant="outline"
      className="m-auto w-full !rounded-md bg-[#171717] !py-4 text-base font-semibold"
      onClick={() => lockAPT(amount)}
    >
      <div className="text-sm">Allocate {amount} APT</div>
    </Button>
  );
};

export const PuzzleButton: React.FC<PuzzleButtonProps> = ({ answer }) => {
  const { network } = useNetwork();
  const { account, network: myNetwork, disconnect, signAndSubmitTransaction } = useWallet();

  const [loading, setLoading] = useState(false);

  const submitAnswer = useCallback(
    async (answerx: string) => {
      setLoading(true); // Start loading

      if (myNetwork?.name?.toLowerCase() === 'mainnet') {
        //   disconnect();
        alert('Switch your network to aptos testnet and try again');
        setLoading(false); // Stop loading

        return;
      }

      if (!account?.address) {
        alert('Please connect to a wallet');
        setLoading(false); // Stop loading

        return;
      }

      const provider = new Provider(networkNameToNetwork(myNetwork?.name as any));

      const payload = puzzlePayload(sha3_512Encode(answerx));

      try {
        const response = await signAndSubmitTransaction(payload);
        await provider.waitForTransaction(response?.hash);

        if (!response?.output.success) return;

        let answer = response.output.events[0].data.answer_correct;
        if (answer) {
          message.success(
            '🎉 Congrats! You solved the final puzzle! Please wait for the announcement!',
          );
        } else {
          message.error('🚫 That’s not quite right! Try again or revisit the clues!');
        }
      } catch (_e) {
        message.error('Rejected Request');
      } finally {
        setLoading(false); // Stop loading
      }
    },
    [network, signAndSubmitTransaction],
  );

  return (
    <>
      <Button
        variant="antc"
        className="m-auto mt-4 w-full max-w-sm rounded-md !bg-[#077d1e] py-2 text-white"
        onClick={() => submitAnswer(answer)}
      >
        {loading ? 'Processing...' : <div className="text-sm">CAPTURE THE FLAG</div>}
      </Button>
    </>
  );
};

export function TelegramComponent() {
  const { account } = useWallet();
  const [inviteLink, setInviteLink] = useState(null);
  const [loading, setLoading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [error, setError] = useState(null);

  const handleRedirect = (link: any) => {
    try {
      if (link) {
        window.open(link, '_blank');
      }
    } catch (error) {
      console.error('Error opening link:', error);
    }
  };

  useEffect(() => {
    // Check for an existing invite link when the component loads
    const fetchExistingLink = async () => {
      if (!account?.address) return;
      // setLoading(true);
      try {
        const result = await getOrCreateInviteLink(account.address);
        if (result.success && result.inviteLink) {
          setInviteLink(result.inviteLink);
        }
      } catch (err: any) {
        console.error('Error fetching existing invite link:', err);
        setError(err.message || 'Failed to fetch invite link.');
      } finally {
        setLoading(false);
      }
    };

    fetchExistingLink();
  }, [account?.address]);

  const fetchOrGenerateInviteLink = async (address: any) => {
    if (!address) return;
    setGenerating(true);
    setError(null);

    try {
      const cachedLink = localStorage.getItem(`inviteLink_${address}`);
      if (false) {
        // Display cached link if it exists
        // setInviteLink(cachedLink);
      } else {
        // Generate new link if not cached
        const result = await getOrCreateInviteLink(address);
        if (result.success) {
          setInviteLink(result.inviteLink);
          // localStorage.setItem(`inviteLink_${address}`, result.inviteLink);
        } else {
          setError(result.error || 'Failed to generate invite link.');
        }
      }
    } catch (err: any) {
      setError(err || 'Failed to generate invite link. Please try again later.');
    } finally {
      setGenerating(false);
    }
  };

  const handleGenerateLink = () => {
    if (account?.address) {
      fetchOrGenerateInviteLink(account.address);
    }
  };

  // if (!account?.address) {
  //   return <Typography.Title level={4}>Please connect your wallet to continue.</Typography.Title>;
  // }

  return (
    <>
      {loading && (
        <Button
          variant="outline"
          loading={generating}
          className="m-auto !w-full !rounded-md bg-[#171717] !py-6"
        >
          <div className="flex">
            <Image
              width={40}
              className="rounded-full"
              height={40}
              src="/logo.png"
              alt="Aptos Dex Antcore"
            />
          </div>
        </Button>
      )}

      {error && (
        <Alert
          message={error}
          type="error"
          showIcon
          action={
            <Button size="small" onClick={handleGenerateLink}>
              Retry
            </Button>
          }
        />
      )}

      {!loading && !error && (
        <>
          {/* Display the generate button */}

          <Button
            variant="outline"
            onClick={handleGenerateLink}
            loading={generating}
            className="m-auto !w-full !rounded-md bg-[#171717] !py-6"
          >
            <div className="flex">
              <Image
                width={40}
                className="rounded-full"
                height={40}
                src="/logo.png"
                alt="Aptos Dex Antcore"
              />
            </div>
            <p data-size="large">{generating ? 'Generating...' : 'Generate Invite Link'}</p>
          </Button>

          <div style={{ textAlign: 'center' }}>
            {inviteLink && (
              <a href={inviteLink} target="_blank" rel="noopener">
                {inviteLink}
              </a>
            )}
          </div>
          {/* Display the invite link if available */}
          {/* {inviteLink && (
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <Button
                variant="outline"
                onClick={() => handleRedirect(inviteLink)}
                className="!rounded-md !py-6 !w-full m-auto bg-[#171717]"
              >
                <div className="flex">
                  <img
                    width="40px"
                    className="rounded-full"
                    src="/logo.png"
                    alt="Aptos Dex Antcore"
                  />
                </div>
                <p data-size="large">{inviteLink}</p>
              </Button>
            </div>
          )} */}
        </>
      )}
    </>
  );
}
