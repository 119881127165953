import { InputTransactionData } from '@aptos-labs/wallet-adapter-core';

export const COLONY_ADDRESS =
  '0xe5e2fdccb99b9827b9af296e454b9303feedfb530896111134fa83f020960783' as const;
export const COLONY_COLLECTION_ID =
  '0x53a8e4d5cf08b2dc64bac73f49b4ddc63274183128989426caadba7e4aa69f40' as const;

export const COLONY_ADDRESS_2 =
  '0xe5e2fdccb99b9827b9af296e454b9303feedfb530896111134fa83f020960783' as const;

export const IFO_ADDRESS =
  '0x3e13c398473143d16c337927fe6be234c0c296e50e688c7614bd9a9f49fc26a5' as const;

export const masterChef_address =
  '0x7608c982218929c9cc508fb2e5f107a4422da889d7caacbc32a02d57e56b7672' as const;

// export const puzzleAddress =
//   '0xc0fc94a4e6ea46b5b899bffc5d601ff9e23f89387d1908f55b3f612921a0f100' as const;

export const puzzleAddress =
  '0x6b7786c83fa715f78425401736e42c7753aa9ce2fda025850fa8add1cb1c0e0b' as const;

// 457616f0cefc11c72a6964607e81d33b4d247b7438a83e07a0554b03dc3a38d4::testnet_campaign
export const MASTERCHEF_MODULE_NAME = 'masterworker' as const;

export const MINT_MODULE_NAME = 'colony' as const;

export function getNftPayload(messageValue: string): InputTransactionData {
  return {
    data: {
      typeArguments: [],
      functionArguments: [messageValue],
      function: `${COLONY_ADDRESS_2}::${MINT_MODULE_NAME}::register_name`,
    },
  };
}

export function mintNftPayload(): InputTransactionData {
  return {
    data: {
      typeArguments: [],
      functionArguments: [],
      function: `${COLONY_ADDRESS_2}::${MINT_MODULE_NAME}::mint_antcore_token`,
    },
  };
}

export function LockAPTPayload(amount: number): InputTransactionData {
  return {
    data: {
      typeArguments: [],
      functionArguments: [amount],
      function: `${IFO_ADDRESS}::campaign::deposit`,
    },
  };
}

export function puzzlePayload(text: string): InputTransactionData {
  return {
    data: {
      typeArguments: [],
      functionArguments: [text],
      function: `${puzzleAddress}::testnet_campaign::submit_puzzle_answer`,
    },
  };
}
